<template>
    <v-container>
        <v-card>
            <v-card-title>Bestellingen</v-card-title>
            <v-card-text>
                <PlaceMaterialOrder/>
                <MaterialOrderTable class="mt-5"/>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
import MaterialOrderTable from "@/components/materialOrder/MaterialOrderTable";
import PlaceMaterialOrder from "@/components/materialOrder/PlaceMaterialOrder.vue";

export default {
    name: "MaterialOrderListView",
    components: {PlaceMaterialOrder, MaterialOrderTable},
}
</script>

<style scoped>

</style>
